
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { GET_CUSTOMER_GROUP } from "@/modules/customer/customer-groups/graphql/Queries";
import { CREATE_CUSTOMER_GROUP } from "@/modules/customer/customer-groups/graphql/Mutations";
import { AddCustomerGroupForm } from "@/modules/customer/customer-groups/interfaces";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new customer group",
	components: {
		Input,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const addCustomerGroupForm = ref<null | HTMLFormElement>(null);

		// Given Add Customer Group Form Interface
		const customerGroupData = ref<AddCustomerGroupForm>({
			name: "",
		});

		// Submit Hanlder Request
		const submitHandlerForm = async () => {
			addCustomerGroupForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						name: customerGroupData.value.name,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_CUSTOMER_GROUP,
						variables: { input: formData },
						update: (store, { data: { create_customer_group } }) => {
							const pervious_record = store.readQuery({
								query: GET_CUSTOMER_GROUP,
								variables: {
									page: 1,
									limit: 10,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_CUSTOMER_GROUP,
								variables: {
									page: 1,
									limit: 10,
								},
								data: {
									customer_groups: {
										...pervious_record.customer_groups,
										data: [create_customer_group, ...pervious_record.customer_groups.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			addCustomerGroupForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("addCustomerGroupAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_customer_group"));
			modal.value.show();
		});

		return {
			loading,
			customerGroupData,
			addCustomerGroupForm,
			submitHandlerForm,
		};
	},
});
