
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import AddCustomerGroup from "@/modules/customer/customer-groups/components/AddCustomerGroup.vue";
import EditCustomerGroup from "@/modules/customer/customer-groups/components/EditCustomerGroup.vue";
import CustomerGroupList from "@/modules/customer/customer-groups/components/CustomerGroupList.vue";

export default defineComponent({
	name: "CustomerGroup",
	components: {
		AddCustomerGroup,
		EditCustomerGroup,
		CustomerGroupList,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.CUSTOMER_GROUPS",
				actionButton: {
					ability: "add_customer_group",
					pageAction: {
						action: "addCustomerGroupAction",
					},
					button: {
						title: `message.ADD message.CUSTOMER_GROUP`,
						icon: "bi bi-plus",
						openModal: true,
					},
				},
				breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.CUSTOMERS", link: "#" }, { name: "message.CUSTOMER_GROUPS" }],
			});
		});
	},
});
