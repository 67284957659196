import { gql } from "graphql-tag";
import { CUSTOMER_GROUP_FIELDS } from "./Fragments";

export const CREATE_CUSTOMER_GROUP = gql`
	mutation CreateCustomerGroup($input: CustomerGroupInput!) {
		create_customer_group(customer_group: $input) {
			...CustomerGroupFields
		}
	}
	${CUSTOMER_GROUP_FIELDS}
`;

export const UPDATE_CUSTOMER_GROUP = gql`
	mutation UpdateCustomerGroup($input: CustomerGroupInput!) {
		update_customer_group(customer_group: $input) {
			...CustomerGroupFields
		}
	}
	${CUSTOMER_GROUP_FIELDS}
`;

export const DELETE_CUSTOMER_GROUP = gql`
	mutation DeleteCustomerGroup($id: Int!) {
		delete_customer_group(id: $id) {
			...CustomerGroupFields
		}
	}
	${CUSTOMER_GROUP_FIELDS}
`;

export default { CREATE_CUSTOMER_GROUP, UPDATE_CUSTOMER_GROUP, DELETE_CUSTOMER_GROUP };
