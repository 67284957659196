import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerGroupList = _resolveComponent("CustomerGroupList")!
  const _component_AddCustomerGroup = _resolveComponent("AddCustomerGroup")!
  const _component_EditCustomerGroup = _resolveComponent("EditCustomerGroup")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_CustomerGroupList)
    ]),
    _createVNode(_component_AddCustomerGroup),
    _createVNode(_component_EditCustomerGroup)
  ], 64))
}