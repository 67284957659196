
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { GET_CUSTOMER_GROUP } from "@/modules/customer/customer-groups/graphql/Queries";
import { UPDATE_CUSTOMER_GROUP } from "@/modules/customer/customer-groups/graphql/Mutations";
import { EditCustomerGroupForm } from "@/modules/customer/customer-groups/interfaces";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit customer group",
	components: {
		Input,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
    const current_page = ref(1);
    const i18n = useI18n();
		const editCustomerGroupForm = ref<null | HTMLFormElement>(null);

		// Given Edit Customer Group Form Interface
		const editCustomerGroupData = ref<EditCustomerGroupForm>({
			id: null,
			name: "",
		});

		// Submit Hanlder Request
		const submitHandlerForm = async () => {
			editCustomerGroupForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						id: editCustomerGroupData.value.id,
						name: editCustomerGroupData.value.name,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_CUSTOMER_GROUP,
						variables: { input: formData },
						update: (store, { data: { update_customer_group } }) => {
							// const pervious_record = store.readQuery({
							// 	query: GET_CUSTOMER_GROUP,
							// 	variables: {
							// 		page: current_page.value,
							// 		limit: 10,
							// 	},
							// }) as Record<any, any>;
							// store.writeQuery({
							// 	query: GET_CUSTOMER_GROUP,
							// 	variables: {
							// 		page: current_page.value,
							// 		limit: 10,
							// 	},
							// 	data: {
							// 		customer_groups: {
							// 			...pervious_record.customer_groups,
							// 		},
							// 		variables: {
							// 			id: update_customer_group.id,
							// 		},
							// 	},
							// });
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
      current_page.value = 1;
      editCustomerGroupForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("editCustomerGroupAction", (customer_group: Record<any, any>) => {
			resetForm();
      current_page.value = customer_group.current_page
      editCustomerGroupData.value.id = customer_group.id;
			editCustomerGroupData.value.name = customer_group.name;
			modal.value = new Modal(document.getElementById("modal_edit_customer_group"));
			modal.value.show();
		});

		return {
			loading,
			editCustomerGroupData,
			editCustomerGroupForm,
			submitHandlerForm,
		};
	},
});
